<template lang='pug'>
  .c-layout
    router-view
</template>

<script>
// import { mapState } from 'vuex'
// import component from '@/components/component.vue'

export default {
  components: {
    // component
  },
  props: {
    // propierty: { required: false, type: String },
  },

  data () {
    return {
      data: ''
    }
  },

  computed: {
    // ...mapState({
    // homeCampaign: state => state.homeCampaign
    // })
  },

  watch: {},
  created () {},
  mounted () {},
  updated () {},
  methods: {}
}
</script>

<style lang="sass" scoped>
</style>
